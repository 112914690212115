/*
Theme Name:     Real Estate 5 Child
Description:    This overrides the default theme for a custom look of the website
Author:         Jason Gallavin
Template:       realestate-5
Version: 1.4
(optional values you can add: Theme URI, Author URI, Version)
*/

@import url("../realestate-5/style.css");
@import url("assets/style/style.css");

/*****************
*   Header
*****************/

header#masthead .logo-link{
    opacity: 1 !important;
}

header#masthead .logo {
    max-width: 200%;
    opacity: 1 !important;
}

#topbar .right:before,  #topbar .right:after{
    display:table;
    content: '';
}
#topbar .left:before, #topbar .left:after {
    display:table;
    content: '';
}

.availability_alerts {
    margin-right:30px;
    font-size: 14px;
}
.availability_alerts_container {
    margin-left:10px;
    display:inline-block;
}

@media only screen and (max-width: 767px)
{
    header#masthead .logo {
        padding: 0 !important;
    }
}



@media only screen and (max-width: 768px) {
    #topbar {
        text-align:center;
    }

    .availability_alerts {
        margin-right: 0;
    }
    .availability_alerts_container {
        margin-left: 0;
    }
    .little-social-links a, .availability_alerts{
        font-size: 18px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .little-social-links li {
        margin-left: 5px;
        margin-right: 10px;
    }
    #topbar .right {
        padding: 10px 0;

    }
    #topbar .left {
        padding-top: 10px;
        margin-bottom: 0 !important;
        font-size: 18px;
    }


    /* Topbar */
    #topbar { padding: 6px 0;}
    #topbar p.left { float: none; text-align: center; margin-bottom: 5px !important;}
    #topbar div.right { float: none; margin: 0 auto;}
    #topbar div.right .left { float: none; margin: 0 auto 5px auto; text-align: center;}
    #topbar .wpml-lang.right { float: none; margin: 0 auto; padding: 0; text-align: center; border: none;}

}





/*****************
*   END Header
*****************/



/*****************
*   Wrapper box shadow
*****************/
#wrapper {
    box-shadow: 0 0 20px rgba(0,0,0,0.5);
}

/*****************
*  END Wrapper box shadow
*****************/

/*****************
*   Footer
*****************/
#footer-widgets .widget {
    float:left;
    width: 33.333%;
    margin-left: 0;
    padding: 0 20px;
}
#footer-widgets .widget > h5:first-child {
    text-align:center;
}
#footer-widgets .widget > h5:first-child:after {
    margin-left: auto;
    margin-right: auto;
}
#footer-widgets .widget {
    text-align:center;
}
#footer-widgets .widget input {
    margin-left:auto;
    margin-right:auto;
}

.row.resources {
    text-align:center;
}
.row.resources .resource img {
    max-width: 40%;
    display: inline-block;
    vertical-align: middle;
    float:none;
}
.row.resources .resource h3 {
    width: 50%;
    display: inline-block;
    text-align: left;
    float:none;
}
@media all and (max-width: 600px)
{
    .row.resources .resource h3
    {
        text-align: center;
    }
}
/*****************
*   END Footer
*****************/








/*********************************
*   Single Estate page Mobile
***********************************/
@media all and (max-width: 480px)
{

    .single-listings #archive-header {
        text-align:center;
    }
    .single-listings .gform_wrapper .ginput_container input[name="input_1"] {
        width:100%;
    }
    .single-listings .gform_wrapper .ginput_container input[readonly="readonly"] {
        color: #777 !important;
    }

    .single-listings .gform_wrapper .ginput_container input[readonly="readonly"]:focus {
        background: #ececec;
    }
}



/*********************************
*   END Single Estate page Mobile
***********************************/








#masthead .container {

    padding:0;
    margin-left:0;
    margin-right:0;
    max-width:100%;
}
.author-info.col.span_9 {
    float:none;
}
input[type="submit"] {
    color:white !important;
}
.checkout-button.button.alt.wc-forward {
    color:white !important;
}
article {
    padding-left:20px;
    padding-right:10%;
}
article .container {
    width:100%;
}
.image-bordered {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    border: 5px solid #fff;
    -webkit-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
}
#masthead {
    padding-top:0 !important;
    padding-bottom: 0 !important;
}
.maps-overlay {
    background: transparent;
    position: relative;
    width: 100%;
    height: 250px;
    top: 250px;
    margin-top: -250px;
}
#masthead .logo {
    top:0 !important;
}
#slides img {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, .2);
    -webkit-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
}
#archive-header {
    background:gray;
}
#archive-header .marB0 {
    color:black;
    font-size:28px;
}
#real-carousel #caption h1 {
    margin-top:18px;
}
#real-carousel li a:hover img {
    opacity: 0.8;
}
p {
    font-size: 14px;
}
.breadcrumb.breadcrumbs.ct-breadcrumbs.right {
    background:gray;
}
#nav #menu-main-menu li a {
    color: #fff !important;
}
#bread-home {
    display:none;
}
header#masthead nav select {
    background: #222222;
    color: white;
    border: 1px solid #808080;
}
.callUs-left a {
    color:white !important;
}
@media all and (max-width: 768px)
{
    .navigationContainer {
        padding-right:0 !important;
    }
    .navigationContainer select {
        border: 1px solid #808080;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        -webkit-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
        box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
    }
}

body {

    background: url("http://gallavinrealestate.com/wp-content/uploads/2015/02/lighter-background.jpg") no-repeat center center fixed !important;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
#wrapper {
    border: none !important;
    /*box-shadow: 0 0 20px #000;*/
}
.cta h1:after {
    background: #fe0000 !important;
}
.btn ,input[type="submit"] {
    background: #fe0000 !important;
}
#advanced-search .marB0, #advanced-search  .marB20 {
    text-align:center;
}
#advanced-search .marB0 {
    font-size:28px;
}
#footer-widgets {
    border-top: none;
}
footer {
    padding: 20px 20px 40px 20px;
}
#masthead .container div.right{
    padding-right: 20px;
}
.widget > h5:after {
    background: #de0000 !important;
}
.social {
    margin-bottom:5px;

}
.social img {
    height: 30px;

}
.social img:hover {
    opacity: 0.6;
}
#socialLinks {

    position:absolute;
    left:95%;
    top:5%;
    z-index:1;

}
/*   This is the bottom social links in the footer  */
.social-bottom {
    margin-left:5px;
    display:inline-block;

}
.social-bottom img {
    height: 30px;

}

.social-bottom img:hover {
    opacity: 0.6;
}
.resources div h3 {
    text-align:left;

}
.resources div img {
    height:32px;
    float:left;
    margin-right:10px;

}

.callUs {
    position:absolute;
    bottom:5%;
    left:0;
    z-index:1;
    color:white;
    margin-left:2%;
    font-size:16px;
}


.container .callUs-left, .container .little-social-links{
    display:none;
}





/* tablet */
@media all and (max-width: 1024px) {

    .container .callUs-left, .container .little-social-links{
        display:inline-block;
    }
    .callUs {
        display:none;
    }
    #socialLinks {
        display:none;
    }
    .container.main {
        max-width:100% !important;
        padding:0 !important;
    }


}

/* mobile phone */
@media all and (max-width: 768px) {
    #real-carousel {
        height: 450px !important;
    }
    .listing img {
        width:100%;
    }
    #real-carousel #caption h1 {
        font-size:16px;
    }
    #footer-widgets {
        text-align:center;
    }
    #footer-widgets #vacancy_email, #footer-widgets h5:after {
        margin-left:auto;
        margin-right:auto;
        text-align:center;
    }
    #sidebar {
        text-align:center;
    }
    #sidebar h5:after,   #sidebar img{
        float: none;
        margin-left: auto;
        margin-right: auto;
    }
    #sidebar h3 {
        margin-top: 15px;
    }

}
@media all and (max-width: 480px) {
    #real-carousel {
        height: 350px !important;
    }

}
@media only screen and (max-device-width: 480px) {

}

/**
*
* Checkout Css
*/
.form-row.notes {
    display:none;
}
.woocommerce-shipping-fields h3:first-child {

    display:none;
}
#billing_company_field {
    display:none;
}
.payment_box.payment_method_paypal {
    width: 80% !important;
}

a.btn {
    color: white !important;
}
/**
*
* Css for shop
*/

.products {
    padding: 20px !important;
}
.woocommerce-result-count {
    padding:20px !important;
}

/**
* Css for houses
*
*/
tr {border-bottom: 1px solid #ccc;}
tr td:first-child {font-weight: bold;}
input[type='text'] {color:black !important;}
textarea {color:black !important;}
.wp-post-image {
    width:100%;
}

#search, #archive {
    .container {
         article {
             margin-bottom:50px;

             &.no-thumb {
                 background: none;
             }
             .content {
                 position:relative;
                 float:none;
                 width: auto;
                 background: #ebebeb;
                 color:black;
                 .more {
                     float:right;
                     position:static;
                     color:white !important;
                 }

             }
             height: auto !important;
             h2.title a span {
                 color:red;
                 background:none;
             }


        }
        .post-meta {
            display:none;
        }

    }

}

.pagination {
    span.current ,a {
        color:white !important;
    }
}

#listings-results.first {
    margin-left: 2% !important;
}

.real-container {
    padding: 0 2%;
}

#sidebar-inner {
    .resource {
        h3 {
            margin-top: 15px;
        }
    }
}